<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>Conversions</h1>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: 'module-analysis' }">
              <v-icon small left>mdi-arrow-left</v-icon> Back to Analysis
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: {},
      loading: false,
      errors: {},
    };
  },

  computed: {},

  methods: {},
};
</script>
